export const useExceptionLogger = () => {
  const { $Sentry } = useNuxtApp();

  const exceptionLogger = (exception: unknown) => {
    console.error(exception);

    if (process.env.NODE_ENV !== 'development') {
      const captureException =
        $Sentry.captureException ?? ($Sentry as any).default.captureException;
      captureException(exception);
    }
  };

  return { exceptionLogger };
};
