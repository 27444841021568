import { default as accountnxAbR2LdmfMeta } from "/opt/render/project/src/eventful-web/pages/account.vue?macro=true";
import { default as indexv1Q3Qz7lQIMeta } from "/opt/render/project/src/eventful-web/pages/index.vue?macro=true";
import { default as installNQJw5hH8YaMeta } from "/opt/render/project/src/eventful-web/pages/install.vue?macro=true";
import { default as _91id_93YBw4hh5o38Meta } from "/opt/render/project/src/eventful-web/pages/invites/[id].vue?macro=true";
import { default as confirmyHAgaKfAb8Meta } from "/opt/render/project/src/eventful-web/pages/login/confirm.vue?macro=true";
import { default as indexzn5owmiHffMeta } from "/opt/render/project/src/eventful-web/pages/login/index.vue?macro=true";
import { default as privacyRATeUg1uR9Meta } from "/opt/render/project/src/eventful-web/pages/privacy.vue?macro=true";
import { default as termspUTk4kFmitMeta } from "/opt/render/project/src/eventful-web/pages/terms.vue?macro=true";
export default [
  {
    name: accountnxAbR2LdmfMeta?.name ?? "account",
    path: accountnxAbR2LdmfMeta?.path ?? "/account",
    meta: accountnxAbR2LdmfMeta || {},
    alias: accountnxAbR2LdmfMeta?.alias || [],
    redirect: accountnxAbR2LdmfMeta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexv1Q3Qz7lQIMeta?.name ?? "index",
    path: indexv1Q3Qz7lQIMeta?.path ?? "/",
    meta: indexv1Q3Qz7lQIMeta || {},
    alias: indexv1Q3Qz7lQIMeta?.alias || [],
    redirect: indexv1Q3Qz7lQIMeta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: installNQJw5hH8YaMeta?.name ?? "install",
    path: installNQJw5hH8YaMeta?.path ?? "/install",
    meta: installNQJw5hH8YaMeta || {},
    alias: installNQJw5hH8YaMeta?.alias || [],
    redirect: installNQJw5hH8YaMeta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/install.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YBw4hh5o38Meta?.name ?? "invites-id",
    path: _91id_93YBw4hh5o38Meta?.path ?? "/invites/:id()",
    meta: _91id_93YBw4hh5o38Meta || {},
    alias: _91id_93YBw4hh5o38Meta?.alias || [],
    redirect: _91id_93YBw4hh5o38Meta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/invites/[id].vue").then(m => m.default || m)
  },
  {
    name: confirmyHAgaKfAb8Meta?.name ?? "login-confirm",
    path: confirmyHAgaKfAb8Meta?.path ?? "/login/confirm",
    meta: confirmyHAgaKfAb8Meta || {},
    alias: confirmyHAgaKfAb8Meta?.alias || [],
    redirect: confirmyHAgaKfAb8Meta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/login/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexzn5owmiHffMeta?.name ?? "login",
    path: indexzn5owmiHffMeta?.path ?? "/login",
    meta: indexzn5owmiHffMeta || {},
    alias: indexzn5owmiHffMeta?.alias || [],
    redirect: indexzn5owmiHffMeta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: privacyRATeUg1uR9Meta?.name ?? "privacy",
    path: privacyRATeUg1uR9Meta?.path ?? "/privacy",
    meta: privacyRATeUg1uR9Meta || {},
    alias: privacyRATeUg1uR9Meta?.alias || [],
    redirect: privacyRATeUg1uR9Meta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: termspUTk4kFmitMeta?.name ?? "terms",
    path: termspUTk4kFmitMeta?.path ?? "/terms",
    meta: termspUTk4kFmitMeta || {},
    alias: termspUTk4kFmitMeta?.alias || [],
    redirect: termspUTk4kFmitMeta?.redirect,
    component: () => import("/opt/render/project/src/eventful-web/pages/terms.vue").then(m => m.default || m)
  }
]