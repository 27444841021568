<template>
  <div class="flex h-full w-full flex-col items-center px-10 py-20">
    <img src="/eventful-logo.png" class="w-full max-w-md" />
    <div class="mt-20 flex flex-col items-center">
      <h2 class="mb-10 text-center text-5xl font-black text-amber-700">
        {{ friendlyError?.title ?? 'An Error Occurred' }}
      </h2>
      <p class="text-center text-2xl font-medium text-amber-900">
        {{
          friendlyError?.message ??
          'There was a problem displaying this page. It may only be available on the app'
        }}
      </p>
      <button
        class="mt-14 rounded-md bg-gradient-to-r from-blue-600 via-blue-700 to-blue-700 px-10 py-2.5 font-roslindale-display-condensed text-3xl text-white shadow-lg active:from-blue-700 active:via-blue-800 active:to-blue-800"
        @click="() => getTheApp()"
      >
        Get the App
      </button>
      <button
        class="mt-6 rounded-md bg-gradient-to-r from-amber-600 via-amber-700 to-amber-700 px-10 py-2.5 font-roslindale-display-condensed text-3xl text-white shadow-lg active:from-amber-700 active:via-amber-800 active:to-amber-800"
        @click="() => backHome()"
      >
        Go to Homepage
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';

const { exceptionLogger } = useExceptionLogger();

useServerSeoMeta({
  title: 'Eventful',
  description: 'Events where you meet wonderful people',
  ogDescription: 'Events where you meet wonderful people',
  ogImage: 'https://eventful.is/android-chrome-512x512.png',
  ogImageSecureUrl: 'https://eventful.is/android-chrome-512x512.png',
});

const props = defineProps({
  error: Object,
});

const { error } = toRefs(props);

const friendlyError = computed(() => {
  exceptionLogger(error?.value);
  if (error?.value?.statusCode === 404) {
    return {
      title: 'Not Found',
      message:
        "We couldn't find the page you were looking for. It may only be available on the app",
    };
  }

  return {
    title: 'An Error Occurred',
    message:
      'There was a problem displaying this page. It may only be available on the app',
  };
});

const backHome = () => clearError({ redirect: '/' });
const getTheApp = () => clearError({ redirect: '/install' });
</script>
