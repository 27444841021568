<template>
  <div class="h-full w-full bg-amber-50">
    <NuxtPage />
  </div>
</template>

<style>
html,
body,
#__nuxt,
#__layout {
  height: 100%;
  width: 100%;
}
</style>
