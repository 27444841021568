import revive_payload_client_bPSN0T90Sp from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kQ5rQziuHp from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qOTdDwdwHl from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_OwZCDJAZVy from "/opt/render/project/src/eventful-web/node_modules/.pnpm/@nuxtjs+supabase@1.2.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_KSoTp5lshl from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_vVa85vfyeV from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_QFUATD8sxt from "/opt/render/project/src/eventful-web/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.4_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/eventful-web/.nuxt/components.plugin.mjs";
import prefetch_client_2aLlkitczt from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_POBLrEmEbc from "/opt/render/project/src/eventful-web/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.12.5_eslint@9.0.0_typescript@5.4.4_vite@5.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_lkvWFDjlUj from "/opt/render/project/src/eventful-web/plugins/01-sentry.ts";
export default [
  revive_payload_client_bPSN0T90Sp,
  unhead_kQ5rQziuHp,
  router_qOTdDwdwHl,
  supabase_client_OwZCDJAZVy,
  payload_client_KSoTp5lshl,
  check_outdated_build_client_vVa85vfyeV,
  plugin_vue3_QFUATD8sxt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2aLlkitczt,
  chunk_reload_client_POBLrEmEbc,
  _01_sentry_lkvWFDjlUj
]