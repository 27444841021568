import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    autoSessionTracking: true,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.35,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });

  nuxtApp.hook('app:mounted', async () => {
    try {
      const { addIntegration, Replay } = await import('@sentry/vue');
      const replay = new Replay({
        maskAllText: true,
        blockAllMedia: true,
        beforeErrorSampling: (event) => {
          if (event.tags?.['no-sentry-replay']) {
            return false;
          }
          return true;
        },
      });
      addIntegration(replay);
    } catch (e) {
      console.warn('Sentry replay not loaded', e);
    }
  });

  return {
    provide: {
      Sentry,
    },
  };
});
